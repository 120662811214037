import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import * as machineDetailsPanelRobotSelectors from '../../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { StyledMachineDetailsRobotCtrList } from './MachineDetailsRobotCtrList.styles';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { IMessage } from 'lib/components/MessageList/MessageList.types';
import { DEFAULT_PAGE_SIZE_VALUE } from 'config/constants';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import {
  CleaningTaskReport,
  MachineClassification,
  SortOrders,
  TaskCompletion,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { MessageList } from 'lib/components/MessageList/MessageList';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { NoStyleButton } from 'lib/components/Button/NoStyleButton/NoStyleButton';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { RoutePaths } from 'config/route-paths';
import { CleaningReportListActions } from 'app/modules/cleaning/cleaning-report-list/state/cleaningReportListActions';
import { END_DATE, OperatingHoursChartUtils, START_DATE } from 'app/modules/cleaning/utils/OperatingHoursChartUtils';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { LoadingIndicator } from 'lib/components/LoadingIndicator/LoadingIndicator';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';
import { CachingStorageSelector } from 'app/cross-cutting-concerns/cachingStorage/state/cachingStorageSelector';
import { cachingStorageActions } from 'app/cross-cutting-concerns/cachingStorage/state/cachingStorageSlice';
import { RobotUtils } from 'app/utils/robot/RobotUtils';

export interface MachineDetailsRobotCtrListProps {
  robot: Machine;
}

export const MachineDetailsRobotCtrList = ({ robot }: MachineDetailsRobotCtrListProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cleaningTaskReports = useSelector(machineDetailsPanelRobotSelectors.selectCTRListRobotData) || [];
  const isInitialLoad = !!useSelector(machineDetailsPanelRobotSelectors.selectCTRListIsInitialLoad);
  const isLoadingMore = !!useSelector(machineDetailsPanelRobotSelectors.selectCTRListIsLoadingMore);
  const totalCount = useSelector(machineDetailsPanelRobotSelectors.selectCTRListRobotTotalCount) || 0;
  const paginationTokens = useSelector(machineDetailsPanelRobotSelectors.selectCTRListRobotPaginationTokens);
  const page = useSelector(machineDetailsPanelRobotSelectors.selectCTRListPage);
  const nextPagePaginationToken = paginationTokens[page + 1];
  const hasNextPage = paginationTokens[page + 1] !== null;

  const startDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);
  const routeNameActive = useSelector(machineDetailsPanelRobotSelectors.selectActiveRoutesName);
  const cleaningTaskReportLatestData = useSelector(machineDetailsPanelRobotSelectors.selectCTRListRobotLatestData);
  const currentExportCtrId = useSelector(machineDetailsPanelRobotSelectors.selectRobotExportCtrPdfCurrentId);
  const [activeCtrId, setActiveCtrId] = useState(cleaningTaskReportLatestData?.id);
  const isUSusers = false;

  const imageList = useSelector(CachingStorageSelector.selectImageStorageList);

  const getCleaningRouteImage = (ctr: CleaningTaskReport): void => {
    const key = `${ctr.machine.id}-${ctr.finishedAt}`;
    const existingImage = imageList[key];
    if (existingImage) {
      setActiveCtrId(ctr.id);
      dispatch(cachingStorageActions.setImageRequest(existingImage));
    } else {
      analyticsLinkActivated({
        linkName: AnalyticsLink.ROBOT_DETAILS_GET_ROUTE_IMAGE,
      });

      setActiveCtrId(ctr.id);

      dispatch(
        cachingStorageActions.getImageRequest({
          machineId: ctr.machine.id,
          finishedAt: ctr.finishedAt,
        })
      );
    }
  };

  const handleRedirectToEventpage = useCallback(
    (ctr: CleaningTaskReport, e: React.MouseEvent<HTMLElement>): void => {
      e.stopPropagation();

      analyticsLinkActivated({
        linkName: AnalyticsLink.CLEANING_REPORTS_ROBOT_DETAILS_REDIRECTED_TO_EVENTS,
      });

      if (ctr.machine.site?.id) {
        dispatch(CleaningReportListActions.setActiveSiteFilter([ctr.machine.site?.id]));
      }
      dispatch(CleaningReportListActions.setActiveMachinesFilter([ctr.machine.id]));
      dispatch(
        CleaningReportListActions.setActivePeriodFilter({
          startDate: startDate || OperatingHoursChartUtils.prepareStartDate(START_DATE).toISOString(),
          endDate: endDate || OperatingHoursChartUtils.prepareEndDate(END_DATE).toISOString(),
        })
      );
      dispatch(CleaningReportListActions.setActiveTaskCompletionFilter([ctr?.taskCompletion as TaskCompletion]));
      dispatch(CleaningReportListActions.setSelectDeviceType(MachineClassification.Robot));
      dispatch(MachineDetailsPanelRobotActions.machineDetailsRobotIsRedirectedActive({ machineId: ctr.machine.id }));
      navigate(RoutePaths.EVENTS);
    },
    [dispatch, navigate, analyticsLinkActivated, startDate, endDate]
  );

  const handleDownloadPdfCtr = useCallback(
    (ctr: CleaningTaskReport, e: React.MouseEvent<HTMLElement>): void => {
      e.stopPropagation();

      analyticsLinkActivated({
        linkName: AnalyticsLink.CLEANING_REPORTS_EXPORT_ROBOT_DETAILS,
      });

      dispatch(
        MachineDetailsPanelRobotActions.machineDetailsRobotExportCtrPdfRequest({
          id: ctr.id,
          timezone: DateTime.getBrowserTimeZone(),
          machineId: ctr.machine.id,
          lang: Translations.getSupportedLanguageCode(i18n.language),
        })
      );
    },
    [dispatch, analyticsLinkActivated, i18n.language]
  );

  const getCoverageClass = (pathCoverage: number): string => {
    if (pathCoverage >= 0.75) {
      return 'coverage-green';
    }
    if (pathCoverage >= 0.5 && pathCoverage < 0.75) {
      return 'coverage-orange';
    }
    return 'coverage-red';
  };

  const isCV50Robot = robot && RobotUtils.getRobotType(robot?.type?.name) === 'CV50';

  const messages = cleaningTaskReports.map<IMessage<CleaningTaskReport>>(ctr => ({
    id: ctr.id,
    description: (
      <div onClick={(): void => getCleaningRouteImage(ctr)}>
        <Row className="robot-ctr-list__routine-row" justify="start" align="middle">
          {ctr.executionId && (
            <p className="robot-ctr-list__routine-data">
              {t('machineDetails.cleaningTaskOverviewRobot.cleaningReportList.routine', {
                routineName: ctr?.routineName || t('robotDashboard.cleaningRoute.label.unNamedRoutine'),
                currentRepeat: ctr?.currentRepeat || 0,
                numberOfRepeats: ctr?.totalRepeats || 0,
              })}
            </p>
          )}
        </Row>
        <Row className="robot-ctr-list__title-row" justify="space-between" align="top">
          <Col className="robot-ctr-list__title-col">
            <p className="robot-ctr-list__data ctr-cleaning-route">
              {ctr.routeName || t('machineDetails.cleaningTaskOverviewRobot.cleaningReportList.basicCleaningMessage')}
            </p>
            {isUSusers ? (
              <p className="robot-ctr-list__data ctr-cleaned-area">
                {ConvertUnitUtils.covertSquareMetersToSquareFeet(ctr.cleanedSquareMeter || 0)}
                <span>&#32;</span>
                {t('common.areaUnitFeetSquare')}
              </p>
            ) : (
              <p className="robot-ctr-list__data ctr-cleaned-area">
                {ctr.cleanedSquareMeter || 0}
                <span>&#32;</span>
                {t('common.areaUnit')}
              </p>
            )}
          </Col>
          <Col className="robot-ctr-list__title-col">
            <p className={`robot-ctr-list__data ctr-task-completion  ${getCoverageClass(ctr.pathCoverage || 0)}`}>
              {ctr.taskCompletion?.toLowerCase() || t('common.NA')}
            </p>
            <p className={`robot-ctr-list__data ctr-path-coverage ${getCoverageClass(ctr.pathCoverage || 0)}`}>
              {Math.round((ctr.pathCoverage || 0) * 100)}&#37;
            </p>
          </Col>
        </Row>
        <Row className="robot-ctr-list__description-row" justify="space-between" align="bottom">
          <Col className="robot-ctr-list__description-col">
            <p className="robot-ctr-list__data ctr-date">
              {DateTime.formatDateByLocale(i18n.language, ctr.finishedAt, DATE_FORMAT_PATTERN.DATE)}
            </p>
            <p className="robot-ctr-list__data ctr-time">
              {DateTime.formatDateByLocale(i18n.language, ctr.startedAt, DATE_FORMAT_PATTERN.TIME_12_HOUR)}
              <span>&#32;</span> &#45; <span>&#32;</span>
              {DateTime.formatDateByLocale(i18n.language, ctr.finishedAt, DATE_FORMAT_PATTERN.TIME_12_HOUR)}
            </p>
          </Col>

          <Col className="robot-ctr-list__description-col">
            <Row gutter={[8, 8]} align="bottom">
              <Col>
                <NoStyleButton
                  onClick={(e): void => handleRedirectToEventpage(ctr, e)}
                  className="robot-ctr-list__redirect-event-page-btn"
                >
                  <SvgIcon name="eventReport" className="event-report-icon" />
                </NoStyleButton>
              </Col>
              <Col>
                {currentExportCtrId === ctr.id ? (
                  <LoadingIndicator size="small" />
                ) : (
                  !isCV50Robot && (
                    <NoStyleButton
                      onClick={(e): void => handleDownloadPdfCtr(ctr, e)}
                      className="robot-ctr-list__export-btn"
                    >
                      <SvgIcon name="download" className="download-icon" />
                    </NoStyleButton>
                  )
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    ),
    originalEntity: ctr,
  }));

  const loadMore = useCallback(() => {
    if (!startDate || !endDate) return;
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportsRequest({
        filter: {
          machineIds: [robot.id],
          machines: {
            classifications: [MachineClassification.Robot],
          },
          period: {
            startAt: startDate,
            endAt: endDate,
          },
          routeName: routeNameActive,
        },
        paginationOptions: {
          limit: DEFAULT_PAGE_SIZE_VALUE,
          paginationToken: nextPagePaginationToken,
        },
        sortOptions: {
          field: 'finishedAt',
          order: SortOrders.Desc,
        },
        append: true,
      })
    );
  }, [dispatch, robot.id, nextPagePaginationToken, startDate, endDate, routeNameActive]);

  useEffect(() => {
    setActiveCtrId(cleaningTaskReportLatestData?.id);
  }, [cleaningTaskReportLatestData]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotCleaningTaskReportsRequest({
        filter: {
          machineIds: [robot.id],
          machines: {
            classifications: [MachineClassification.Robot],
          },
          period: {
            startAt: startDate,
            endAt: endDate,
          },
          routeName: routeNameActive,
        },
        paginationOptions: {
          limit: DEFAULT_PAGE_SIZE_VALUE,
          paginationToken: '',
        },
        sortOptions: {
          field: 'finishedAt',
          order: SortOrders.Desc,
        },
        append: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, routeNameActive, startDate, endDate]);

  return (
    <StyledMachineDetailsRobotCtrList className={classnames('robot-details-ctr-list')}>
      <MessageList<CleaningTaskReport>
        title=""
        messages={messages}
        isInitialLoad={isInitialLoad}
        isLoadingMore={isLoadingMore}
        hasNextPage={hasNextPage}
        totalCount={totalCount}
        loadMore={loadMore}
        activeMessageId={activeCtrId}
        emptyView={
          <>
            <div className="robot-details-ctr-list__empty-icon">
              <SvgIcon name="emptyCtr" />
            </div>
            <div className="robot-details-ctr-list__empty-text">
              {t('machineDetails.cleaningTaskOverviewRobot.cleaningReportList.emptyMessage')}
            </div>
          </>
        }
      />
    </StyledMachineDetailsRobotCtrList>
  );
};
